/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { setPlans } from '../plans/plansSlice';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const patchFilterTypes = createAsyncThunk('filterTypesSlice/patchFilterTypes', async (payload, { rejectWithValue, dispatch, getState }) => {
  try {
    if (payload.external_shopify_id) {
      const { data: { error, details } } = await axios.patch('/order/skio-details', payload);
      const plans = getState().plans.data.map(plan => {
        return plan.external_shopify_id === payload.external_shopify_id
          ? { ...plan, details }
          : { ...plan };
      });
      await dispatch(setPlans(plans));
      if (error) throw new Error(error);
    } else {
      const response = await axios.patch('/order/legacy-details', payload);
      await dispatch(setPlans(response?.data));
    }
  } catch (e) {
    const error = e.response
      ? { ...e.response?.data, status: e.response?.status }
      : { message: e.message, status: 400 }
    dispatch(addGlobalError(error));
    return rejectWithValue(error);
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const filterTypesSlice = createSlice({
  name: 'filterTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(patchFilterTypes.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(patchFilterTypes.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(patchFilterTypes.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const selectFilterTypesData = state => state.filterTypes.data;
export const selectFilterTypesStatus = state => state.filterTypes.status;
export const selectFilterTypesError = state => state.filterTypes.error;

export default filterTypesSlice.reducer;
