import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelRefill,
} from '../../features/cancelRefill/cancelRefillSlice';
import { selectPlansData } from '../../features/plans/plansSlice';
import { delayRefill } from '../../features/delayRefill/delayRefillSlice';
import { SOURCE } from '../../../shared/enums';
import { patchFilterTypes } from '../../features/filterTypes/filterTypesSlice';
import { patchPlan } from '../../features/plans/patchPlanSlice';
import { addGlobalError, addGlobalMessage } from '../../features/root/rootSlice';
import { fetchActiveRegionalPrices } from '../../features/regionalPrices/regionalPricesSlice';
import { MONTHS } from '../../../shared/constants';
import { convertToOrdinal } from '../../../shared/utils';
import useIp from '../../hooks/useIp';
import { selectQaTokensData } from '../../features/plans/qaTokenSlice';

const AutoRefillPlan = loadable(() => import('./AutoRefillPlan'));
const ModalDelay = loadable(() => import('../ModalDelay'));
const ModalExpedit = loadable(() => import('../ModalExpedit'));
const ModalPaymentUpdates = loadable(() => import('../ModalPaymentUpdates'));
const ModalPaymentMethodRedirect = loadable(() => import('../ModalPaymentMethodRedirect'));
const ModalDelayType = loadable(() => import('../ModalDelayType'));
const ModalCancelSure = loadable(() => import('../ModalCancelSure'));
const ModalEdit = loadable(() => import('../ModalEdit'));
const ModalEditSave = loadable(() => import('../ModalEditSave'));
const ModalCancelSureSecond = loadable(() => import('../ModalCancelSureSecond'));
const ModalCancelSorry = loadable(() => import('../ModalCancelSorry'));
const ModalAccountEditAddress = loadable(() => import('../ModalAccountEditAddress'));
const ModalAccountAddCard = loadable(() => import('../ModalAccountAddCard'));

const AutoRefill = ({ activePlans, inactivePlans }) => {
  const dispatch = useDispatch();
  const { region } = useIp();
  const plans = useSelector(selectPlansData);
  const qaTokensData = useSelector(selectQaTokensData);

  const [ showModalDelay, setShowModalDelay ] = useState(false);
  const [ showModalExpedit, setShowModalExpedit ] = useState(false);
  const [ showModalPaymentUpdates, setShowModalPaymentUpdates ] = useState(false);
  const [ showModalPaymentMethodRedirect, setShowModalPaymentMethodRedirect ] = useState(false);
  const [ showModalDelayType, setShowModalDelayType ] = useState(false);
  const [ hasChangesValue, setHasChangesValue ] = useState(false);
  const [ showModalEdit, setShowModalEdit ] = useState(false);
  const [ showModalEditSave, setShowModalEditSave ] = useState(false);
  const [ showModalSure, setShowModalSure ] = useState(false);
  const [ showModalSureSecond, setShowModalSureSecond ] = useState(false);
  const [ showModalCancelSorry, setShowModalCancelSorry ] = useState(false);
  const [ showModalAccountEditAddress, setShowModalAccountEditAddress ] = useState(false);
  const [ showModalAccountAddCard, setShowModalAccountAddCard ] = useState(false);

  // const [ activePlans, setActivePlans ] = useState([]);
  // const [ inactivePlans, setInactivePlans ] = useState([]);
  const [ delayData, setDelayData ] = useState({ id: null, type: null });

  const [ activePlan, setActivePlan ] = useState(null);
  const [ details, setDetails ] = useState([]);

  const setDefaultDetails = () => setDetails([ ...(activePlan.details) ]);

  useEffect(async () => {
    await dispatch(fetchActiveRegionalPrices(region));
  }, [ region ]);

  useEffect(() => {
    if (showModalEdit && activePlan) {
      const { details: originalDetails } = activePlan;
      if (!details.length || JSON.stringify(originalDetails) === JSON.stringify(details)) {
        setDefaultDetails();
      }
    } else if (!showModalEdit) {
      setDetails([]);
    }
  }, [ activePlan, showModalEdit ]);

  useEffect(() => {
    const checkFilterTypesChanges = newDetails => {
      let changes = false;

      newDetails.forEach((variant, idx) => {
        if (variant.variant !== activePlan.details[idx]?.variant
          || variant.color !== activePlan.details[idx]?.color
          || variant.count !== activePlan.details[idx]?.count) {
          changes = true;
        }
      });

      if (newDetails.length !== activePlan.details?.length) changes = true;
      return changes;
    };

    if (activePlan && Object.keys(activePlan).length > 0 && details && details.length > 0) {
      const changes = checkFilterTypesChanges(details);
      setHasChangesValue(changes);
    }
  }, [ activePlan, details ]);

  useEffect(() => {
    if (qaTokensData) {
      setShowModalPaymentMethodRedirect(true);
    }
  }, [ qaTokensData ]);

  const checkFilterTypesChanges = newDetails => {
    let hasChanges = false;

    newDetails.forEach((variant, idx) => {
      if (variant.variant !== activePlan.details[idx]?.variant
        || variant.color !== activePlan.details[idx]?.color
        || variant.count !== activePlan.details[idx]?.count) {
        hasChanges = true;
      }
    });

    if (newDetails.length !== activePlan.details?.length) {
      hasChanges = true;
    }

    return hasChanges;
  };

  const patchShippingAddressHandle = address => {
    dispatch(patchPlan({
      id: activePlan.id,
      source: SOURCE.MAH,
      ...address,
    })).then(() => {
      setShowModalAccountEditAddress(false);
    });
  };

  // const getActivePlans = plans => plans.filter(value => [ '1', '2' ].includes(value.has_subscription));
  // const getInactivePlans = plans => plans.filter(value => [ '0', '3' ].includes(value.has_subscription));

  useEffect(() => {
    if (plans) {
      // setActivePlans(getActivePlans(plans));
      // setInactivePlans(getInactivePlans(plans));

      if (activePlan) {
        const updatedActivePlan = plans.filter(plan => (activePlan.external_shopify_id ? plan.external_shopify_id === activePlan.external_shopify_id : plan.id === activePlan.id));
        if (updatedActivePlan.length) setActivePlan(updatedActivePlan[0]);
      }
    }
  }, [ plans ]);

  const showModalDelayHandle = plan => {
    setActivePlan(plan);
    setShowModalDelay(true);
  };
  const showModalDelayTypeHandle = plan => {
    setActivePlan(plan);
    setShowModalDelayType(true);
  };
  const showModalExpeditHandle = plan => {
    setActivePlan(plan);
    setShowModalExpedit(true);
  };
  const showModalEditHandle = plan => {
    // console.log('SHOW MODAL', plan);
    setActivePlan(plan);
    setShowModalEdit(true);
  };
  const showModalSureSecondHandle = () => {
    setShowModalSure(false);
    setShowModalSureSecond(true);
  };

  const moveToDashboard = () => {
    setShowModalSureSecond(false);
    setShowModalEdit(false);
  };

  const moveToDelay = () => {
    setShowModalSureSecond(false);
    setShowModalDelayType(true);
  };

  const showModalCancelLastHandle = () => {
    setShowModalSureSecond(false);
    setShowModalCancelSorry(true);
  };

  const setDelayTypeHandle = (plan, type) => {
    setDelayData({
      id: plan.id,
      external_shopify_id: plan.external_shopify_id,
      type,
    });

    setShowModalDelayType(false);
    setShowModalDelay(true);
  };

  const saveDelayHandle = date => {
    const formattedDate = `${MONTHS[date.getMonth()]}, ${convertToOrdinal(date.getDate())}`;
    dispatch(delayRefill({
      ...delayData,
      next_refill_date: date,
    })).then(() => {
      setShowModalDelay(false);
      dispatch(addGlobalMessage({
        message: `Your next refill date has been successfully updated, next shipment will process on ${formattedDate}`,
      }));
    });
  };

  const closeModalEditHandle = () => {
    if (checkFilterTypesChanges(details)) {
      setShowModalEditSave(true);
    } else {
      setShowModalEdit(false);
    }
  };

  const closeModalSaveHandle = () => {
    setDefaultDetails();
    setShowModalEditSave(false);
    setShowModalEdit(false);
  };

  /**
   * @param autoClose
   */
  const savePlanHandle = async (autoClose = true) => {
    const { error } = await dispatch(patchFilterTypes({
      id: activePlan.id,
      external_shopify_id: activePlan.external_shopify_id,
      source: SOURCE.MAH,
      details,
    }));
    if (error) return;

    setHasChangesValue(false);
    if (autoClose) {
      setShowModalEditSave(false);
      setShowModalEdit(false);
    }
  };

  const cancelAutoRefillHandle = payload => {
    dispatch(cancelRefill(payload)).then(() => {
      setShowModalCancelSorry(false);
      setShowModalEdit(false);
      dispatch(addGlobalMessage({ message: 'Your Auto-Refill plan has been successfully cancelled' }));
    });
  };

  const setShowModalAccountAddCardHandle = plan => {
    setActivePlan(plan);

    if (!plan.external_shopify_id) {
      setShowModalAccountAddCard(true);
    }

    if (plan.external_shopify_id) {
      if (!plan.external_shopify_id.includes('gid://shopify/')) {
        setShowModalPaymentUpdates(true);
      } else {
        dispatch(addGlobalError({
          message: 'Sorry, we couldn’t complete your request. Please reach out to our Care Squad(support@milacares.com) to update payment method.',
        }));
      }
    }
  };

  return (
    <div className='auto-refill'>
      <h2 className='auto-refill__title'>
        Auto-Refill Plans
      </h2>
      {!!activePlans?.length && activePlans.map(plan => (
        <AutoRefillPlan
          plan={plan}
          showModalExpeditHandle={showModalExpeditHandle}
          showModalDelayHandle={showModalDelayHandle}
          showModalDelayTypeHandle={showModalDelayTypeHandle}
          showModalEditHandle={showModalEditHandle}
          setShowModalAccountAddCardHandle={setShowModalAccountAddCardHandle}
        />
      ))}
      {!!inactivePlans?.length
        && inactivePlans.map(plan => (
          <AutoRefillPlan
            plan={plan}
            inactive
            setShowModalAccountAddCardHandle={setShowModalAccountAddCardHandle}
          />
        ))}
      <ModalEdit
        show={showModalEdit}
        setShow={closeModalEditHandle}
        hasChangesValue={hasChangesValue}
        activePlan={activePlan}
        details={details}
        savePlanHandle={savePlanHandle}
        setDefaultDetails={setDefaultDetails}
        setDetails={setDetails}
        setShowModalExpedit={setShowModalExpedit}
        setShowModalDelay={setShowModalDelayType}
        setShowModalSure={setShowModalSure}
        setShowModalAccountEditAddress={setShowModalAccountEditAddress}
        setShowModalPaymentUpdates={setShowModalPaymentUpdates}
      />
      <ModalExpedit
        show={showModalExpedit}
        setShow={setShowModalExpedit}
        activePlan={activePlan}
      />
      <ModalPaymentUpdates
        show={showModalPaymentUpdates}
        setShow={setShowModalPaymentUpdates}
        activePlan={activePlan}
      />
      <ModalPaymentMethodRedirect
        show={showModalPaymentMethodRedirect}
        setShow={setShowModalPaymentMethodRedirect}
        activePlan={activePlan}
      />
      <ModalDelay
        show={showModalDelay}
        setShow={setShowModalDelay}
        activePlan={activePlan}
        saveDelay={saveDelayHandle}
      />
      <ModalDelayType
        show={showModalDelayType}
        setShow={setShowModalDelayType}
        activePlan={activePlan}
        setDelayTypeHandle={setDelayTypeHandle}
      />
      <ModalEditSave
        show={showModalEditSave}
        setShow={setShowModalEditSave}
        activePlan={activePlan}
        savePlanHandle={savePlanHandle}
        closeModalSaveHandle={closeModalSaveHandle}
      />
      <ModalCancelSure
        show={showModalSure}
        setShow={setShowModalSure}
        activePlan={activePlan}
        setShowModalSureSecond={showModalSureSecondHandle}
      />
      <ModalCancelSureSecond
        show={showModalSureSecond}
        setShow={setShowModalSureSecond}
        activePlan={activePlan}
        moveToDashboard={moveToDashboard}
        moveToDelay={moveToDelay}
        setShowModalCancelLast={showModalCancelLastHandle}
      />
      <ModalCancelSorry
        show={showModalCancelSorry}
        setShow={setShowModalCancelSorry}
        activePlan={activePlan}
        cancelAutoRefillHandle={cancelAutoRefillHandle}
      />
      <ModalAccountEditAddress
        show={showModalAccountEditAddress}
        setShow={setShowModalAccountEditAddress}
        activePlan={activePlan}
        patchShippingAddressHandle={patchShippingAddressHandle}
      />
      <ModalAccountAddCard
        show={showModalAccountAddCard}
        setShow={setShowModalAccountAddCard}
        bill={activePlan}
      />
    </div>
  );
};

export default AutoRefill;
