import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError, addGlobalMessage } from '../root/rootSlice';
import { setPlans } from './plansSlice';
import { setQaToken } from './qaTokenSlice';

/**
 * @type {{patchPlan: {error: null, status: string}, fetchPlans: {data: null, error: null, status: string}}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const patchPlan = createAsyncThunk('patchPlan/patchPlan', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.patch('/plan', payload);

    if (response?.data?.qaToken) {
      await dispatch(setQaToken(response?.data));
    } else {
      await dispatch(setPlans(response?.data));
    }
    dispatch(addGlobalMessage({ message: 'The subscription has been re-activated.' }));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearShipments: reducers.clearShipments}, string>}
 */
export const patchPlanSlice = createSlice({
  name: 'patchPlan',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(patchPlan.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(patchPlan.fulfilled, state => {
        state.status = STATUS.SUCCEEDED;
      })
      .addCase(patchPlan.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const selectPatchPlanData = state => state.patchPlan.data;
export const selectPatchPlanStatus = state => state.patchPlan.status;
export const selectPatchPlanError = state => state.patchPlan.error;

export default patchPlanSlice.reducer;
