import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';
import {FILTERS_INFO} from "../../../shared/constants";

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  activePrices: [],
  status: STATUS.IDLE,
  error: null,
};

export const fetchRegionalPrices = createAsyncThunk('regionalPrices/fetchRegionalPrices', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/regional-prices', { params: { region: payload.join(',') }});
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const fetchActiveRegionalPrices = createAsyncThunk('regionalPrices/fetchActiveRegionalPrices', async (region, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await axios.get('/active-regional-prices', { params: { region }});
    const filters = data.map(filter => {
      const variant = filter.sku.slice(-2);
      const FILTER_LOCAL_INFO = FILTERS_INFO[variant];
      return {
        variantId: filter.id,
        color: FILTER_LOCAL_INFO.color,
        count: 1,
        variant,
        sku: filter.sku,
        price: filter.price,
        title: filter.title,
        subTitle: filter.subTitle,
        text: FILTER_LOCAL_INFO.text,
      };
    }).sort((a, b) => {
      return b.sku.localeCompare(a.sku);
    });

    return filters;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{activePrices: [], data: null, error: null, status: string}, {clearShipments: reducers.clearShipments}, string>}
 */
export const regionalPricesSlice = createSlice({
  name: 'regionalPrices',
  initialState,
  reducers: {
    clearRegionalPrices: state => {
      state.activePrices = initialState.activePrices;
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRegionalPrices.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchActiveRegionalPrices.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchRegionalPrices.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchActiveRegionalPrices.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.activePrices = action.payload;
      })
      .addCase(fetchRegionalPrices.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      })
      .addCase(fetchActiveRegionalPrices.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearRegionalPrices } = regionalPricesSlice.actions;

export const selectActiveRegionalPrices = state => state.regionalPrices.activePrices;
export const selectRegionalPricesData = state => state.regionalPrices.data;
export const selectRegionalPricesStatus = state => state.regionalPrices.status;
export const selectRegionalPricesError = state => state.regionalPrices.error;

export default regionalPricesSlice.reducer;
