/**
 * @type {Readonly<{CC: {color: string, price: string, text: string, title: string}, BB: {color: string, price: string, text: string, title: string}, BS: {color: string, price: string, text: string, title: string}, OR: {color: string, price: string, text: string, title: string}, MB: {color: string, price: string, text: string, title: string}, RP: {color: string, price: string, text: string, title: string}, HW: {color: string, price: string, text: string, title: string}}>}
 */
const FILTERS_INFO = Object.freeze({
  BS: {
    title: 'The 🤧 Big Sneeze.',
    text: 'Helping you fight back against allergens.',
    color: '#FFC140',
    price: '69',
  },
  CC: {
    title: 'The 🐶 Critter Cuddler.',
    text: 'Clearing the air for fur families.',
    color: '#F42D53',
    price: '79',
  },
  BB: {
    title: 'The 🍃 Basic Breather.',
    text: 'For the everyday lover of clean air.',
    color: '#3E4649',
    price: '59',
  },
  RP: {
    title: 'The 👶 Rookie Parent.',
    text: 'Making the air clean and safe for your littles.',
    color: '#8852F6',
    price: '69',
  },
  HW: {
    title: 'The 🚧 Home Wrecker.',
    text: 'Protecting you from that “new home” smell.',
    color: '#F27E0F',
    price: '79',
  },
  MB: {
    title: 'The 👩 Mama to Be.',
    text: 'Extra protection when you’re breathing for two.',
    color: '#1CE4D5',
    price: '79',
  },
  OR: {
    title: 'The 🤯 Overreactor.',
    text: 'Protection from ALL the things.',
    color: '#0A56EA',
    price: '99',
  },
  BBM: {
    title: 'The 🍃 Basic Breather (Mini).',
    text: 'For the everyday lover of clean air.',
    color: '#3E4649',
    price: '39',
  },
  BSM: {
    title: 'The 🤧 Big Sneeze (Mini).',
    text: 'Helping you fight back against allergens.',
    color: '#FFC140',
    price: '49',
  },
  CCM: {
    title: 'The 🐶 Critter Cuddler (Mini).',
    text: 'Clearing the air for fur families.',
    color: '#F42D53',
    price: '59',
  },
  ORM: {
    title: 'The 🤯 Overreactor (Mini).',
    text: 'Protection from ALL the things.',
    color: '#0A56EA',
    price: '69',
  },
});

const NAV_ROUTES = Object.freeze([
  {
    pathname: '/dashboard',
    text: 'Dashboard',
    iconClass: 'icon-filters',
  },
  // {
  //   pathname: '/account',
  //   text: 'Account',
  //   iconClass: 'icon-account',
  // },
  {
    pathname: '/history',
    text: 'Order History',
    iconClass: 'icon-basket',
  },
  {
    pathname: '/referral',
    text: 'Refer a Friend',
    iconClass: 'icon-share',
  },
]);

const REGION_LABELS = Object.freeze({
  CA: {
    city: 'City',
    zipcode: 'Postal Code',
    state: 'Province',
    placeholders: {
      zipcode: 'Add postal code here ...',
      state: 'Add province here ...',
    },
  },
  GB: {
    city: 'City',
    zipcode: 'Postal Code',
    state: 'Country',
    placeholders: {
      zipcode: 'Add postal code here ...',
      state: 'Add country here ...',
    },
  },
  AU: {
    city: 'City',
    zipcode: 'Zipcode',
    state: 'State',
    placeholders: {
      zipcode: 'Add zipcode here ...',
      state: 'Add state here ...',
    },
  },
  SG: {
    city: 'Planning Area',
    zipcode: 'Zipcode',
    state: 'Country',
    placeholders: {
      zipcode: 'Add zipcode here ...',
      state: 'Add country here ...',
    },
  },
  EU: {
    city: 'City',
    zipcode: 'Postal Code',
    state: 'Country',
    placeholders: {
      zipcode: 'Add postal code here ...',
      state: 'Add country here ...',
    },
  },
  US: {
    city: 'City',
    zipcode: 'Zipcode',
    state: 'State',
    placeholders: {
      zipcode: 'Add zipcode here ...',
      state: 'Add state here ...',
    },
  },
});

const MONTHS = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];

/**
 * @type {Readonly<{AMEX: string, EFTPOS: string, FULL_NAME_AMEX: string, JCB: string, VISA: string, UNION_PAY: string, MASTER_CARD: string, DICOVER: string, DINERS_CLUB: string}>}
 */
const CARD_TYPES = Object.freeze({
  VISA: 'visa',
  AMEX: 'amex',
  FULL_NAME_AMEX: 'american express',
  DICOVER: 'discover',
  MASTER_CARD: 'mastercard',
  UNION_PAY: 'unionpay',
  JCB: 'jcb',
  DINERS_CLUB: 'diners',
  EFTPOS: 'eftpos',
});

/**
 * @type {Readonly<{ADDRESS: string, ADDRESS_WITH_NUMS: string}>}
 */
const REGEXP = Object.freeze({
  ADDRESS: /^[\/()a-zA-Z\u00C0-\u02FF\u0300-\u036F\s,'-.]+$/,
  ADDRESS_WITH_NUMS: /^[\/()a-zA-Z0-9\u00C0-\u02FF\u0300-\u036F\s,'-.]+$/,
});

/**
 * An array of page paths that should not display the navigation bar.
 *
 * @type {Array<string>}
 */
const PAGES_WITHOUT_NAV = Object.freeze([ '/' ]);

export {
  FILTERS_INFO,
  NAV_ROUTES,
  REGION_LABELS,
  MONTHS,
  CARD_TYPES,
  REGEXP,
  PAGES_WITHOUT_NAV,
};
