/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { axios } from '../../shared/clients';

const useIp = () => {
  const [ ip, setIP ] = useState('');
  const [ region, setRegion ] = useState('');
  // TODO: useRef ?????

  useEffect(async () => {
    // console.log('USE IP DID MOUNT', {ip, region});
    if (!ip) {
      const ipResponse = await fetch('https://api.ipify.org/?format=json');
      const ipData = await ipResponse.json();
      setIP(ipData?.ip);
    }
  }, []);

  useEffect(async () => {
    if (ip && !region) {
      const { data } = await axios.post('/region', { ip });
      if (data.error) console.error(data.error);
      setRegion(data.region);
    }
  }, [ ip ]);

  // useEffect(() => {
  //   console.log(`IP ADDRESS: "${ip}/${region}"`);
  // }, [ region ]);

  return { ip, region };
};

export default useIp;
