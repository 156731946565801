/**
 * @type {Readonly<{SIGINT: string, SIGTERM: string}>}
 */
const SIGNALS = Object.freeze({
  SIGINT: 'SIGINT',
  SIGTERM: 'SIGTERM',
});

/**
 * @type {Readonly<{MAH: string}>}
 */
const SOURCE = Object.freeze({
  MAH: 'mah',
});

/**
 * @type {Readonly<{LOGOUT: string, AUTH: string, API_LOGOUT: string, API_AUTH: string, LANDING: string}>}
 */
const UNPROTECTED_ROUTES = Object.freeze({
  LANDING: '/',
  AUTH: '/login',
  LOGOUT: '/logout',
  TOKEN_EXCHANGE: '/token-exchange',
  API_AUTH: '/api/login',
  API_LOGOUT: '/api/logout',
  API_RESET_PWD: '/api/user/reset',
  API_TOKEN_EXCHANGE: '/api/token-exchange',
});

/**
 * @type {Readonly<{CANT_AFFORD_OR_DONT_WANT_TO_PAY: number, DONT_NEED_REFILL_YET: number, PREFER_NOT_TO_ANSWER: number, MIGHT_BE_AWAY: number}>}
 */
const ORDER_NOTE_TYPE = Object.freeze({
  MIGHT_BE_AWAY: 1,
  DONT_NEED_REFILL_YET: 2,
  CANT_AFFORD_OR_DONT_WANT_TO_PAY: 3,
  PREFER_NOT_TO_ANSWER: 4,
});

/**
 * @type {Readonly<{EUR: string, AUD: string, SGD: string, GBP: string, USD: string, CAD: string}>}
 */
const CURRENCY_SIGN = Object.freeze({
  USD: '$',
  CAD: '$',
  GBP: '£',
  AUD: '$',
  SGD: '$',
  EUR: '€',
});

/**
 * @type {Readonly<{LOADING: string, FAILED: string, IDLE: string, PREFETCHED: string, SUCCEEDED: string}>}
 */
const STATUS = Object.freeze({
  IDLE: 'idle',
  LOADING: 'loading',
  PREFETCHED: 'prefetched',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
});

/**
 * @type {Readonly<{'4': string, '5': string, '6': string, '7': string, '8': string, '9': string, '10': string}>}
 *
 * @todo Is this only used from the legacy DB? If so we may be able to deprecate
 */
const PRODUCT_VARIANT_NAMES = Object.freeze({
  4: 'The Basic Breather',
  5: 'The Big Sneeze',
  6: 'The Rookie Parent',
  7: 'The Critter Cuddler',
  8: 'The Home Wrecker',
  9: 'The Mama-to-Be',
  10: 'The Overreactor',
});

/**
 * @type {Readonly<{'The Mama-to-Be': string, 'The Critter Cuddler': string, 'The Rookie Parent': string, 'The Overreactor': string, 'The Basic Breather': string, 'The Big Sneeze': string, 'The Home Wrecker': string}>}
 */
const PRODUCT_VARIANTS_ABBREVIATION = Object.freeze({
  'The Big Sneeze': 'BS',
  'The Critter Cuddler': 'CC',
  'The Basic Breather': 'BB',
  'The Rookie Parent': 'RP',
  'The Home Wrecker': 'HW',
  'The Mama-to-Be': 'MB',
  'The Overreactor': 'OR',
  'The Basic Breather (Mini)': 'BBM',
  'The Big Sneeze (Mini)': 'BSM',
  'The Critter Cuddler (Mini)': 'CCM',
  'The Overreactor (Mini)': 'ORM',
});

/**
 * @type {Readonly<{DE: string, BE: string, PT: string, DK: string, LU: string, IT: string, FR: string, HU: string, ES: string, SE: string, AT: string, CZ: string, IE: string, PL: string, RO: string, NL: string}>}
 */
const SUPPORTED_REGIONS = Object.freeze({
  EU: {
    AT: 'AUSTRIA',
    BE: 'BELGIUM',
    FR: 'FRANCE',
    DE: 'GERMANY',
    IE: 'IRELAND',
    IT: 'ITALY',
    LU: 'LUXEMBOURG',
    NL: 'NETHERLANDS',
    PT: 'PORTUGAL',
    ES: 'SPAIN',
    DK: 'DENMARK',
    SE: 'SWEDEN',
    CZ: 'CZECH REPUBLIC',
    HU: 'HUNGARY',
    PL: 'POLAND',
    RO: 'ROMANIA',
  },
  GB: [
    'England',
    'Northern Ireland',
    'Scotland',
    'Wales',
  ],
  US: [
    'US',
  ],
  SG: [
    'SG',
  ],
  CA: [
    'CA',
  ],
  AU: [
    'AU',
  ],
});

/**
 * @type {*}
 */
const FILTERS_ICONS = Object.freeze({
  BS: '🤧',
  CC: '🐶',
  BB: '🍃',
  RP: '👶',
  HW: '🚧',
  MB: '👩',
  OR: '🤯',
  BBM: '🍃',
  BSM: '🤧',
  CCM: '🐶',
  ORM: '🤯',
});

/**
 * @type {*}
 */
const ADDRESS_TYPES = Object.freeze({
  SHIPPING: 'shipping',
  BILLING: 'billing',
});

/**
 * @type {*}
 */
const PLAN_STATUSES = Object.freeze({
  PAUSED: {
    key: 'PAUSED',
    header_label: 'Paused',
    label: 'Associated With',
    mid_label: '',
    button_label: 'Edit Card',
  },
  FAILED: {
    key: 'FAILED',
    header_label: 'Failed',
    label: 'Associated With',
    mid_label: '',
    button_label: 'Edit Card',
  },
  VALID: {
    key: 'VALID',
    header_label: 'Active',
    label: 'Associated With',
    mid_label: '',
    button_label: 'Edit Card',
  },
  EXPIRED: {
    key: 'EXPIRED',
    header_label: 'Action Required',
    label: 'Your Card',
    mid_label: 'is expired',
    button_label: 'Update Card',
  },
  EXPIRING: {
    key: 'EXPIRING',
    header_label: 'Action Required',
    label: 'Your Card',
    mid_label: 'is about to expire',
    button_label: 'Update Card',
  },
  EMPTY: {
    key: 'EMPTY',
    header_label: 'Inactive',
    label: '',
    mid_label: '',
    button_label: 'Add Card',
  },
  INACTIVE: {
    key: 'INACTIVE',
    header_label: 'Action Required',
    label: 'Your Card',
    mid_label: '',
    button_label: 'Update Card',
  },
});

/**
 * Enum representing reference types.
 * @readonly
 * @enum {string}
 */
const REF_TYPES = Object.freeze({
  SHOPIFY_ORDER: 'shopify_order',
  ORDER: 'order',
  REFILL: 'refill',
});

export {
  SIGNALS,
  SOURCE,
  UNPROTECTED_ROUTES,
  ORDER_NOTE_TYPE,
  CURRENCY_SIGN,
  STATUS,
  PRODUCT_VARIANT_NAMES,
  PRODUCT_VARIANTS_ABBREVIATION,
  SUPPORTED_REGIONS,
  FILTERS_ICONS,
  ADDRESS_TYPES,
  PLAN_STATUSES,
  REF_TYPES,
};
